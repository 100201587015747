<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>
<script>
	export default {
		name: "App",
		provide() {
			return {
				reload: this.reload,
			};
		},
		data() {
			return {
				isRouterAlive: true,
			};
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => (this.isRouterAlive = true));
			},
		},
	};
</script>
<style lang="scss">
	@import "../common/iconfont/iconfont.css";
	@import "../common/common.css";
	@import "../common/animation.css";
	.container{
		background-color: #F2F3F7;
	}
	.el-breadcrumb{
		line-height: 2 !important;
	}
	.btn-prev,.btn-next{
		border: 1px solid;
		background-color: #fff !important;
		border: 1px solid #D9D9D9 !important;
	}
	.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
		font-weight: initial !important;
		color: #999 !important;
	}
	.el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover{
		color: #00337D !important;
	}
	.el-pagination.is-background .el-pager li:not(.disabled).active{
		background-color: #00337D !important;
		color: #fff !important;
	}
	.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
		border: 1px solid;
		background-color: #fff !important;
		border: 1px solid #D9D9D9 !important;
		min-width: 25px !important;
	}
	.el-pager li,.el-pagination button, .el-pagination span:not([class*=suffix]){
		height: 25px !important;
		line-height: 25px !important;
	}
	#mapEcharts{
		background-color: #F2F3F7;
		// border: 5px solid;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
		img{
			width:200px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			margin: -10px -20px 7px;
		}
	}
	.dx-more {
		position: absolute;
		bottom: 0;
		color: #fff;
		left: 50%;
		width: 50px;
		margin-left: -25px;
		@media screen and(min-width:320px) and (max-width:767px) {
			// width: 44px;
			// margin-left: -10px;
		}
		p{
			color: #fff;
			font-size: 12px;
			margin-bottom: 20px;
		}
		&-img {
			border: 2px solid;
			width: 45px;
			height: 45px;
			cursor: pointer;
			border-radius: 50%;
			line-height: 45px;
			text-align: center;
			// @media screen and(min-width:320px) and (max-width:767px) {
			// 	line-height: 30px;
			// 	height: 40px;
			// 	width: 40px;
			// }
			img {
				width: 15px !important;
				height: 15px !important;
				// margin-left: 1px;
				// @media screen and(min-width:320px) and (max-width:767px) {
				// 	height: 10px !important;
				// 	width: 10px !important;
				// }
			}
		}
	
		&::after {
			content: '';
			display: block;
			width: 2px;
			height: 35px;
			margin: 0 auto;
			background-color: #fff;
			@media screen and(min-width:320px) and (max-width:767px) {
				height: 25px;
			}
		}
	}
	.no-more {
		height: 150px;
		line-height: 150px;
		text-align: center;
		color: #666;
	}
</style>
