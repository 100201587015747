import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		meta: {
			title: '江西耐普'
		},
		component: () => import('../views/login/index.vue'),
	},
	{
		path: '/index',
		meta: {
			title: '首页'
		},
		component: () => import('../views/index/index.vue'),
	},
	{
		path: '/supplier',
		meta: {
			title: '世界主流衬板供应商'
		},
		component: () => import('../views/supplier/index.vue'),
	},
	{
		path: '/supplierinfo',
		name: 'supplierinfo',
		meta: {
			title: '世界主流衬板供应商详情'
		},
		component: () => import('../views/supplier/info.vue'),
	},
	{
		path: '/operation',
		meta: {
			title: '矿山磨机设计及运行数据'
		},
		component: () => import('../views/operation/index.vue'),
	},
	{
		path: '/operationinfo',
		name: 'operationinfo',
		meta: {
			title: '矿山磨机设计及运行数据详情'
		},
		component: () => import('../views/operation/info.vue'),
	},
	{
		path: '/operationtree',
		name: 'operationtree',
		meta: {
			title: '矿山磨机设计及运行数据流程图信息'
		},
		component: () => import('../views/operation/tree.vue'),
	},
	{
		path: '/design',
		name: 'design',
		meta: {
			title: '磨机衬板辅助设计及数据驱动优化'
		},
		component: () => import('../views/design/index.vue'),
	},
	{
		path: '/designinfo',
		name: 'designinfo',
		meta: {
			title: '磨机衬板辅助设计模块'
		},
		component: () => import('../views/design/info.vue'),
	},
	{
		path: '/database',
		name: 'database',
		meta: {
			title: '磨机衬板磨损点云数据库'
		},
		component: () => import('../views/database/index.vue'),
	},
	{
		path: '/databaseupload',
		name: 'databaseupload',
		meta: {
			title: '磨机衬板磨损点云数据库-上传'
		},
		component: () => import('../views/database/upload.vue'),
	},
	{
		path: '/databaseinfo',
		name: 'databaseinfo',
		meta: {
			title: '磨机衬板磨损点云数据库-详情'
		},
		component: () => import('../views/database/info.vue'),
	},
	{
		path: '/monitor',
		name: 'monitor',
		meta: {
			title: '产品磨损在线监测模块'
		},
		component: () => import('../views/monitor/index.vue'),
	},
	{
		path: '/monitorinfo',
		name: 'monitorinfo',
		meta: {
			title: '产品磨损在线监测模块-详情'
		},
		component: () => import('../views/monitor/info.vue'),
	},
	{
		path: '/user',
		name: 'user',
		meta: {
			title: '个人中心'
		},
		component: () => import('../views/user/index.vue'),
	},
	{
		path: '/updateinfo',
		name: 'updateinfo',
		meta: {
			title: '个人中心-修改资料'
		},
		component: () => import('../views/user/ios/updateInfo.vue'),
	},
	{
		path: '/updatepassword',
		name: 'updatepassword',
		meta: {
			title: '个人中心-修改密码'
		},
		component: () => import('../views/user/ios/updatePassword.vue'),
	},
	{
		path: '/cloudData',
		name: 'cloudData',
		meta: {
			title: '个人中心-点云数据'
		},
		component: () => import('../views/user/cloudData.vue'),
	},
	{
		path: '/cloudDatainfo',
		name: 'cloudDatainfo',
		meta: {
			title: '个人中心-点云数据详情'
		},
		component: () => import('../views/user/cloudDatainfo.vue'),
	},
	{
		path: '/databaseupdate',
		name: 'databaseupdate',
		meta: {
			title: '个人中心-点云数据修改'
		},
		component: () => import('../views/user/update.vue'),
	},
	{
		path: '/message',
		name: 'message',
		meta: {
			title: '个人中心-留言'
		},
		component: () => import('../views/user/message.vue'),
	},
	{
		path: '/messageList',
		name: 'messageList',
		meta: {
			title: '个人中心-留言'
		},
		component: () => import('../views/user/message/list.vue'),
	},
	{
		path: '/messageinfo',
		name: 'messageinfo',
		meta: {
			title: '个人中心-留言'
		},
		component: () => import('../views/user/message/info.vue'),
	},
	{
		path: '/cbinfo',
		name: 'cbinfo',
		meta: {
			title: '磨机衬板磨损点云数据库-衬板详情'
		},
		component: () => import('../views/database/cbinfo.vue'),
	},
	{
		path: '/standard',
		name: 'standard',
		meta: {
			title: '标准化列表'
		},
		component: () => import('../views/standard/index.vue'),
	},
	{
		path: '/standardlist',
		name: 'standardlist',
		meta: {
			title: '标准化详情'
		},
		component: () => import('../views/standard/list.vue'),
	},
	{
		path: '/patent',
		name: 'patent',
		meta: {
			title: '专利列表'
		},
		component: () => import('../views/patent/index.vue'),
	},
	{
		path: '/patentlist',
		name: 'patentlist',
		meta: {
			title: '专利详情'
		},
		component: () => import('../views/patent/list.vue'),
	},
	{
		path: '/literature',
		name: 'literature',
		meta: {
			title: '文献列表'
		},
		component: () => import('../views/literature/index.vue'),
	},
	{
		path: '/literaturelist',
		name: 'literaturelist',
		meta: {
			title: '文献详情'
		},
		component: () => import('../views/literature/list.vue'),
	},
	{
		path: '/case',
		name: 'case',
		meta: {
			title: '经典案例'
		},
		component: () => import('../views/case/list.vue'),
	},
	{
		path: '/testing',
		name: 'testing',
		meta: {
			title: '产品运行在线监测'
		},
		component: () => import('../views/testing/index.vue'),
	},
	{
		path: '/datalake',
		name: 'datalake',
		meta: {
			title: '全球产品大数据湖'
		},
		component: () => import('../views/datalake/index.vue'),
	},
	{
		path: '/preview',
		name: 'preview',
		meta: {
			title: '文件预览'
		},
		component: () => import('../views/preview/preview.vue'),
	}
]

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		// to, from, savedPosition
		// console.log(to,from,savedPosition);
		return {
			x: 0,
			y: 0
		}
	}
})

export default router
