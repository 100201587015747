// request.js
import axios from "axios";
import store from '../src/store/index.js'
import Cookie from 'js-cookie';
// import cookie from 'vue-cookie'
// axios.defaults.withCredentials=true;
import {
	Message
} from "element-ui";
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers['Remember-Token'] = cookie.get('rememberToken');
axios.defaults.headers['Front-Remember-Token'] = Cookie.get('frontRemembertoken');
const BASE_API = store.state.httpUrl;
window.BASE_API = BASE_API;
let i = 0;
// axios.interceptors.request.use(
// 	config => {
// 		if (localStorage.getItem('rememberToken')) {
// 			config.headers.RememberToken = localStorage.getItem('rememberToken');
// 		}

// 		return config;
// 	},
// 	error => {
// 		return Promise.reject(error);
// 	});
//定义方法
axios.interceptors.response.use(
	config => {
		// console.log(config);
		if (config.data.status == "401" && i == 0) {
			i = 1;
			alert(config.data.message);
			Cookie.remove("frontRemembertoken");
			Cookie.remove("userinfo");
			window.location.href = '/';
			setTimeout(function() {
				i = 0
			}, 2000);
			return false;
		} else if (config.data.status == "406" && i == 0) {
			Message({
				message: "此账号暂无权限，请联系管理员！",
				type: "error"
			});
			// window.location.href = '/#/user';
		}
		return config;
	}
);
export function post(url, params) {
	return axios.post(BASE_API + url, this.$qs.stringify(params)); //如果不需要转json的话，那么第二次参数就是params
}
export function get(url, params) {
	return axios.get(BASE_API + url, {
		params
	});
}

export function getDownload(url, params) {
  return axios.post(BASE_API + url ,this.$qs.stringify(params) , {responseType: 'blob'});
}