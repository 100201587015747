import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import qs from 'qs'
import axios from 'axios'
import ElementUI from 'element-ui';
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import {
  get,
  post
} from "../utils/request.js";
Vue.prototype.$qs = qs;
Vue.config.productionTip = false
Vue.prototype.$get = get;
Vue.prototype.$echarts = echarts;
Vue.prototype.$post = post;
Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
